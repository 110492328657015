.course-info-drawer {
    .course-info-content {
        height: calc(100% - 80px);
        overflow-y: auto;
        padding-bottom: 24px;
    }

    .info-section {
        margin-bottom: 24px;

        .info-item {
            background: #fafafa;
            padding: 16px;
            border-radius: 8px;
            
            .ant-typography-secondary {
                display: block;
                margin-bottom: 8px;
            }

            h5.ant-typography {
                margin: 0;
            }
        }
    }

    .drawer-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 24px;
        background: white;
        border-top: 1px solid #f0f0f0;
    }

    .ant-drawer-body {
        padding: 24px;
        padding-bottom: 0;
    }
} 