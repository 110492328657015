.cs-container {
  background: transparent;

  .section {

    .left {

      .ant-select-outlined {
        border-color: rgb(86, 86, 86);
      }

      .ant-select {
        width: 100%;
        margin-bottom: 15px;

        .ant-select-arrow {
          color: rgb(200, 200, 200); /* 设置下拉按钮的颜色 */
        }

        .ant-select-selector {
          background: rgb(66, 66, 66);
          color: rgb(230, 230, 230);
          border-color: rgb(86, 86, 86);

          :focus {
            border: 1px solid rgb(76, 76, 76);
            color: red;
          }

          :focus-visible {
            box-shadow: none; /* 移除焦点时的阴影 */
            border-color: initial; /* 恢复边框颜色 */
          }

          .ant-select-selection-item {
            color: rgb(230, 230, 230);
          }
        }
      }
    }

    .right {
      display: flex;
    }
  }
}

.ant-select-dropdown {
  background: rgb(66, 66, 66);
  border: 1px solid rgb(86, 86, 86);

  .option {
    color: rgb(230, 230, 230);
  }

  .option.ant-select-item-option-active {
    background-color: rgb(86, 86, 86);
    color: rgb(230, 230, 230);
  }
}
