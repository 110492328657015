.right_container {
    width: 240px;
    height: 100%;
}

.head_video {
    width: 240px;
    height: 180px;
    background-color: black;
}

.video-head-container {
    width: 240px;
    height: 180px;
}

.tab_content_t {
    width: 100%;
    height:calc(100% - 180px);
    flex-grow: 1;
    background-color: rgb(66, 66, 66);
    display: flex;
    flex-direction: column;
}

 .chat-room-title {
     background-color: rgb(79, 89, 184);
     color: white;

     text-align: left;
     display: flex;
     margin-left:10px;

     min-height: 48px;
 }

 .room-title-teacher {
     background-color: transparent;
 }

 .chat-room-title-text {
     margin: auto;
     margin-left: 10px;
     width: 100%;
     text-align: left;
}
