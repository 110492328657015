.course_item_main_container {
    margin: 15px;
    background: rgba(99, 99, 99, 0.03);
    border-radius: 5px;
    padding: 5px 5px 5px 0;

    display: flex;
    height: 80px;

    .order {
        background: #00a4ff;
        color: white;
        height: 20px;
        line-height: 20px;
        text-align: center;
        width: 40px;
        border-radius: 0 5px 5px 0;
    }

    .info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .name {
            flex-grow: 1;
            font-weight: bold;
            color: gray;
        }

        .text {
            font-size: small;
        }
    }

    .action-buttons {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: 10px;
    }

    .bind-button {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: rgb(0, 164, 255);
        font-size: 18px;

        &:hover {
            color: rgb(0, 148, 230);
        }
    }

    .state {
        padding: 8px 20px;
        border-radius: 6px;
        background: #1890ff;
        color: white;
        cursor: pointer;
        font-size: 14px;

        &.old {
            background: #d9d9d9;
            cursor: not-allowed;
        }

        &.disabled {
            background: #d9d9d9;
            cursor: not-allowed;
            opacity: 0.7;
        }
    }
}
