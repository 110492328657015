.join-class-search-container {
    display: flex;
    margin: 10px;
}

.join-class-container {
    width: 100%;
    height: 100%;
}

.class-info {
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 500px;
    width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.class-info-header {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.class-info-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.class-info-code {
    color: #666;
}

.class-members {
    flex: 1;
    overflow-y: auto;
    padding: 0 20px;
    margin-top: 10px;
}

.member-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
}

.member-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}

.join-button-container {
    padding: 20px;
    text-align: center;
    border-top: 1px solid #f0f0f0;
}

.join-button {
    width: 100%;
    height: 40px;
    background-color: #00a4ff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.join-button:hover {
    background-color: #0093e6;
}

.empty-container {
    text-align: center;
    color: #999;
    padding: 20px;
}
