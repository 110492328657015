$hoverColor : rgb(0, 0, 120);
$normalColor : rgb(33, 33, 33);

.nav-item {
  cursor: pointer;
  //height: 80px;
  margin-bottom: 10px;
  vertical-align: middle;
  font-size: 15px;
  position: relative;

  margin-left: 4px;
  margin-right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: $normalColor;

  box-sizing: border-box;

  padding: 5px;

  .no-event {
    pointer-events: none;
  }

  .pic {
    width: calc(100% - 30px);
    height: auto;
  }

  .circle {
    width: 26px; /* 圆的直径 */
    height: 16px; /* 圆的直径 */
    //border-radius: 50%; /* 使其成为圆形 */
    border: 1px solid rgba(120, 120, 120, 1.0); /* 圆环的宽度和颜色 */
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(22, 210, 105); /* 数字的颜色 */
    background-color: rgba(80, 80, 80, 1.0); /* 圆的背景颜色 */

    position: absolute;
    left: 8px;
    top: 8px;
  }
}

.thumb-container {

  height: 100%;

  .nav-widget {
    color: $normalColor;
    background: rgb(60, 60, 60);
    height: 100%;

    overflow: auto;
  }
}


