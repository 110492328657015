.sidebar {
    background: rgb(45, 45, 45);
    width: 100px !important;
    margin: 0;
    padding: 0;
    flex: 0 0 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;

    .avatar-container {
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: rgb(45, 45, 45);
        padding: 0;
        box-sizing: border-box;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            display: block;
        }
    }

    .logo {
        height: 64px;
        padding: 16px;
        text-align: center;
        color: white;
        font-size: 20px;
        font-weight: bold;
        background: #1a1a1a;
    }

    .ant-menu {
        background: transparent;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 80px);
        border-inline-end: none !important;

        .ant-menu-item {
            height: 56px !important;
            width: 92px !important;
            margin: 4px auto !important;
            padding: 8px 0 !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #888 !important;
            gap: 2px;

            .anticon {
                font-size: 22px;
                margin: 0 !important;
                line-height: 1;
            }

            .ant-menu-title-content {
                font-size: 12px;
                margin: 0 !important;
                line-height: 1;
            }

            &:hover {
                color: #fff !important;
            }

            &.ant-menu-item-selected {
                background-color: #1a1a1a !important;
                color: #fff !important;
                &::after {
                    display: none;
                }
            }

            &.settings-item {
                margin-top: auto !important;
            }
        }
    }
} 