.console_page_container {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.console_page_left {
    width: 64px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    border-right: 1px solid #f0f0f0;
}

.mainDiv {
    flex: 1;
    overflow: auto;
}

.head_pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    /*padding-top: 24px;*/

}

.head-pic-container {
    display: flex;
    justify-content: center;
}

.cloud_disk_page_container_iframe {
    border: none;
    width: 100%;
    height: 100%;
}

.cloud_disk_page_container {
    width: calc(100% - 72px);
    height: calc(100% - 10px);
}
