.top-rtc-container {
    /*height: 160px;*/
    display: flex;
    background-color: rgb(60, 60, 60);
    height: 100%;
}

.video-container-t {
    width: 140px;
    height: 100px;
    border: 2px solid rgb(10, 192, 249);
    border-radius: 6px;
    margin-left: 20px;
    margin-bottom: 10px;
    /*margin-right: 30px;*/
    margin-top: 10px;
}

.video_rtc {
    width: 100%;
    height: 100%;
}
