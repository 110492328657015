.avatar-upload-container {
    display: flex;
    padding: 20px;
    gap: 40px;
}

.upload-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.upload-box {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.upload-content {
    text-align: center;
    color: #666;
}

.upload-icon {
    font-size: 32px;
    margin-bottom: 8px;
}

.upload-text {
    font-size: 14px;
}

.upload-tip {
    color: #999;
    font-size: 12px;
    text-align: center;
}

.avatar-preview {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.preview-title {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.preview-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.preview-avatar div {
    color: #999;
    font-size: 12px;
}

.preview-40 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.preview-80 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
} 