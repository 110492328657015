.import-students-container {
    .import-tip {
        margin-bottom: 16px;
        color: #666;
    }

    .textarea-controls {
        margin-bottom: 16px;
    }

    .phone-input {
        font-family: monospace;
        cursor: text !important;
        user-select: text !important;
        -webkit-user-select: text !important;
        -moz-user-select: text !important;
        -ms-user-select: text !important;
        
        textarea {
            cursor: text !important;
            user-select: text !important;
            -webkit-user-select: text !important;
            -moz-user-select: text !important;
            -ms-user-select: text !important;
        }
    }
} 