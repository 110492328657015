.class_main_container {
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 8px;
    margin: 4px 8px;
    
    &:hover {
        background-color: #f5f5f5;
    }
    
    &.active {
        background-color: #e6f4ff;
        
        .class_container {
            border-left: 4px solid #1890ff;
        }
    }

    .class_container {
        display: flex;
        align-items: center;
        padding: 12px;
        border-left: 4px solid transparent;
        transition: border-left 0.3s ease;
        
        .class_head_pic {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            margin-right: 12px;
            object-fit: cover;
        }
        
        .class-name {
            font-size: 14px;
            color: #333;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        
        .spacer {
            flex-grow: 1;
        }
    }
} 