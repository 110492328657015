.course-item {
    padding: 21px 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.course-item:hover {
    background-color: #f5f5f5;
}

.course-main-info {
    flex: 1;
}

.course-name {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
    font-weight: 500;
}

.course-details {
    color: #666;
    font-size: 18px;
}

.course-details span {
    margin-right: 16px;
}

.course-details span:last-child {
    margin-right: 0;
}

.time-info {
    color: #1890ff;
}

.duration {
    color: #666;
}

.teacher-name {
    color: #666;
}

.status-tag {
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 16px;
}

.status-tag.started {
    background-color: #e6f7ff;
    color: #1890ff;
}

.status-tag.not-started {
    background-color: #f5f5f5;
    color: #999;
} 