.public-course-container {
    padding: 20px;
    width: 1200px;
    margin: 0 auto;

    .grade-filter {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        background: #fff;
        padding: 16px 20px;
        border-radius: 4px;

        .grade-label {
            color: #333;
            margin-right: 20px;
            font-size: 14px;
            padding-top: 4px;
            font-weight: 600;
        }

        .grade-options {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            flex: 1;

            .grade-item {
                cursor: pointer;
                padding: 4px 12px;
                border-radius: 4px;
                font-size: 13px;
                color: #666;
                transition: all 0.3s;

                &:hover {
                    color: #1890ff;
                }

                &.active {
                    background-color: #1890ff;
                    color: white;
                }
            }
        }
    }

    .course-list {
        display: grid;
        grid-template-columns: repeat(5, 200px);
        column-gap: 20px;
        row-gap: 24px;
        justify-content: center;
        align-content: start;
        height: calc(100vh - 140px);
        overflow-y: auto;
        padding: 1px;
        
        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 4px;

            &:hover {
                background: #999;
            }
        }
        
        .course-card {
            background: #fff;
            border-radius: 12px;
            border: 1px solid #f0f0f0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            width: 200px;
            height: 200px;
            position: relative;

            .device-id {
                position: absolute;
                top: 12px;
                left: 12px;
                right: 12px;
                z-index: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                .device-name {
                    background: rgba(0, 0, 0, 0.5);
                    color: #fff;
                    padding: 4px 8px;
                    border-radius: 4px;
                    font-size: 12px;
                }

                .close-icon {
                    background: rgba(0, 0, 0, 0.5);
                    color: #fff;
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 12px;

                    &:hover {
                        background: rgba(0, 0, 0, 0.7);
                    }
                }
            }

            .preview-area {
                height: 120px;
                background: #f0f0f0;
                border-radius: 12px 12px 0 0;
            }

            .card-footer {
                padding: 8px;
                flex: 1;
                display: flex;
                flex-direction: column;

                .title-row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;

                    .course-title {
                        color: #333;
                        font-size: 14px;
                        line-height: 1.4;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        text-align: left;
                        font-weight: 600;
                    }
                }

                .info-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: auto;

                    .left-section {
                        display: flex;
                        align-items: center;

                        .avatar {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background: #f0f0f0;
                            margin-right: 6px;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #999;
                            font-size: 12px;
                        }

                        .user-name {
                            color: #666;
                            font-size: 12px;
                            margin-right: 6px;
                        }
                    }

                    .time {
                        color: #999;
                        font-size: 12px;
                        margin-left: auto;
                    }
                }

                .setup-btn {
                    width: 100%;
                    border-radius: 6px;
                }
            }
        }
    }
}
