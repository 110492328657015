.bind-course-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 700px;
}

.bind-input-section {
    margin-bottom: 24px;
}

.bind-label {
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
}

.bind-input {
    width: 100%;
}

.bind-iframe-section {
    flex: 1;
    margin: 0;
    overflow: hidden;
}

.bind-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.bind-button-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
}

.selected-name {
    margin-right: auto;
    color: #1890ff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 12px;
}

.bind-submit-button {
    background-color: rgb(0, 164, 255);
}

.bind-submit-button:hover {
    background-color: rgb(0, 148, 230) !important;
} 