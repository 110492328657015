.video-replay-container {
    width: 100%;
    height: 100%;
    background: #000;
    position: relative;
    overflow: hidden;

    .video-wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        .video-player {
            width: 100%;
            height: 100%;
            object-fit: contain;
            outline: none;
        }

        .pause-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.3);
            cursor: pointer;

            .pause-icon {
                font-size: 120px;
                color: rgba(255, 255, 255, 0.8);
                transition: all 0.3s ease;

                &:hover {
                    color: #fff;
                    transform: scale(1.1);
                }
            }
        }

        .controls {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
            padding: 20px;
            transform: translateY(100%);
            transition: transform 0.3s ease;
            opacity: 0;

            &.show {
                transform: translateY(0);
                opacity: 1;
            }

            .progress-slider {
                margin-bottom: 16px;
                padding: 4px 0;

                .ant-slider-rail {
                    background-color: rgba(255, 255, 255, 0.3);
                }

                .ant-slider-track {
                    background-color: #1890ff;
                }

                .ant-slider-handle {
                    border-color: #1890ff;
                    background-color: #fff;
                    
                    &:hover, &:focus {
                        border-color: #1890ff;
                    }
                }
            }

            .controls-content {
                display: flex;
                align-items: center;
                gap: 20px;

                .control-button {
                    background: none;
                    border: none;
                    cursor: pointer;
                    padding: 16px;
                    color: #fff;
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.1);

                    &:hover {
                        transform: scale(1.1);
                        background: rgba(255, 255, 255, 0.2);
                    }

                    &:focus {
                        outline: none;
                    }
                }

                .time-display {
                    color: #fff;
                    font-size: 16px;
                    font-family: monospace;
                    margin: 0 20px;
                }

                .speed-button {
                    background: none;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    border-radius: 4px;
                    color: #fff;
                    padding: 8px 16px;
                    cursor: pointer;
                    font-size: 16px;
                    transition: all 0.3s ease;

                    &:hover {
                        background: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
    }
}

