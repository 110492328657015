.schedule-page {
    height: 100%;
    background: #fff;
    margin: 16px;
    border-radius: 8px;
    overflow: auto;
}

.schedule-container {
    display: flex;
    gap: 24px;
    padding: 24px;
    min-height: calc(100% - 48px);  /* 减去padding */
}

.schedule-calendar {
    flex: 0 0 300px;
    border-right: 1px solid #f0f0f0;
    padding-right: 24px;
}

.schedule-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.date-header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
}

.schedule-list {
    flex: 1;
    padding: 16px 0;
}

.no-class {
    text-align: center;
    color: #999;
    padding: 32px 0;
}

/* 自定义日历样式 */
.ant-picker-calendar {
    background: #fff;
}

.ant-picker-calendar-header {
    padding: 8px;
}

/* 选中日期的样式 */
.ant-picker-cell-selected .ant-picker-calendar-date {
    background: #1890ff;
    color: #fff;
}

.calendar-divider {
    height: 1px;
    background-color: #f0f0f0;
    margin-top: 16px;
}

.calendar-footer {
    padding: 24px 16px;
    text-align: center;
    margin-top: 100px;
}

.calendar-footer .ant-btn {
    min-width: 80px;
}

/* 修改年月选择下拉面板的样式 */
.ant-picker-dropdown .ant-picker-panel-container {
    background-color: #ffffff !important;
}

.ant-picker-dropdown .ant-picker-header {
    color: rgba(0, 0, 0, 0.85) !important;
    border-bottom: 1px solid #f0f0f0 !important;
}

.ant-picker-dropdown .ant-picker-header button {
    color: rgba(0, 0, 0, 0.85) !important;
}

.ant-picker-dropdown .ant-picker-header button:hover {
    color: #1890ff !important;
}

.ant-picker-dropdown .ant-picker-cell {
    color: rgba(0, 0, 0, 0.85) !important;
}

.ant-picker-dropdown .ant-picker-cell:hover .ant-picker-cell-inner {
    background: #f5f5f5 !important;
}

.ant-picker-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
    background: #1890ff !important;
    color: #fff !important;
}

/* 年月选择下拉面板样式 */
:where(.css-dev-only-do-not-override-190m0jy).ant-picker-dropdown .ant-picker-panel-container {
    background-color: white;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-picker-dropdown .ant-picker-content th {
    color: rgba(0, 0, 0, 0.88);
}

:where(.css-dev-only-do-not-override-190m0jy).ant-picker-dropdown .ant-picker-cell {
    color: rgba(0, 0, 0, 0.88);
}

:where(.css-dev-only-do-not-override-190m0jy).ant-picker-dropdown .ant-picker-header {
    color: rgba(0, 0, 0, 0.88);
}

:where(.css-dev-only-do-not-override-190m0jy).ant-picker-dropdown .ant-picker-header button {
    color: rgba(0, 0, 0, 0.88);
}

:where(.css-dev-only-do-not-override-190m0jy).ant-picker-dropdown .ant-picker-cell:hover .ant-picker-cell-inner {
    background: #f0f0f0;
}

.light-theme-dropdown {
    background-color: #fff !important;
}

.light-theme-dropdown .ant-picker-panel-container,
.light-theme-dropdown .ant-picker-decade-panel,
.light-theme-dropdown .ant-picker-year-panel,
.light-theme-dropdown .ant-picker-month-panel {
    background-color: #fff !important;
}

.light-theme-dropdown .ant-picker-header,
.light-theme-dropdown .ant-picker-header button,
.light-theme-dropdown .ant-picker-cell {
    color: rgba(0, 0, 0, 0.88) !important;
}

.light-theme-dropdown .ant-picker-content th {
    color: rgba(0, 0, 0, 0.88) !important;
}

.light-theme-dropdown .ant-picker-cell:hover .ant-picker-cell-inner {
    background: #f5f5f5 !important;
}

.light-theme-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
    background: #1890ff !important;
    color: #fff !important;
} 