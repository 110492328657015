.user-profile {
    width: 100%;
    background: white;
    color: #333;
}

.profile-header {
    background-color: rgb(243, 244, 245);
    padding: 24px 0;
}

.avatar-section {
    display: flex;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 24px;
}

.avatar-container {
    margin-right: 24px;
    position: relative;
}

.avatar {
    background: white !important;
    color: #00a4ff !important;
    font-weight: bold;
    transition: opacity 0.3s;
}

.avatar:hover {
    opacity: 0.8;
    cursor: pointer;
}

.user-info {
    flex: 1;
    display: flex;
    align-items: center;
}

.nickname-section {
    display: flex;
    align-items: center;
}

.nickname-display {
    display: flex;
    align-items: center;
    gap: 8px;
}

.nickname {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.edit-icon {
    color: #1890ff;
    cursor: pointer;
    font-size: 16px;
    padding: 4px;
    transition: all 0.3s;
}

.edit-icon:hover {
    color: #40a9ff;
    background-color: rgba(24, 144, 255, 0.1);
    border-radius: 4px;
}

.user-phone {
    color: #666;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.qr-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.profile-content {
    padding: 0 16px;
}

.profile-item {
    margin-bottom: 20px;
    display: flex;
    padding: 8px 0;
    /* border-bottom: 1px solid #f0f0f0; */
    align-items: center;
}

.item-label {
    font-size: 16px;
    width: 80px;
    color: #666;
}

.item-value {
    font-size: 16px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    gap: 12px;
}

.verified {
    font-size: 16px;
    color: #00a4ff;
}

.edit-button {
    font-size: 16px;
    padding: 0 8px;
    color: #00a4ff;
}

.profile-item .item-value {
    display: flex;
    align-items: center;
    gap: 8px;
}

.profile-item .ant-input {
    height: 32px;
    padding: 4px 11px;
}

.profile-item .ant-input:focus {
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.logout-section {
    border-top: 1px solid #e8e8e8;
    margin-top: 32px;
    padding-top: 32px;
    display: flex;
    justify-content: center;
}

.logout-button {
    color: #666;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 24px !important;
}

.logout-button:hover {
    color: #1890ff !important;
}

.logout-button .anticon {
    font-size: 24px !important;
}

.logout-text {
    font-size: 18px !important;
}

.logout-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.avatar-upload-container {
    display: flex;
    padding: 20px;
    gap: 40px;
}

.upload-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.upload-tip {
    color: #999;
    font-size: 12px;
}

.avatar-preview {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.preview-title {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.preview-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.preview-avatar div {
    color: #999;
    font-size: 12px;
}

.preview-40 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.preview-80 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
} 