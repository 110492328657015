/* src/ChatBox.css */

.room_main {
    overflow: auto;
    height: calc(100% - 42px);
}

/* 滚动条整体样式 */
::-webkit-scrollbar {
    width: 6px; /* 滚动条宽度 */
    height: 6px; /* 滚动条高度 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background: transparent; /* 轨道背景色 */
    border-radius: 10px; /* 轨道圆角 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    background: #888; /* 滑块背景色 */
    border-radius: 10px; /* 滑块圆角 */
}

/* 滑块在悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* 悬停时的滑块背景色 */
}

.input-group {
    /*padding-top: 6px;*/
    display: flex;
    align-items: center;
    margin: 6px;
}

.input-container {
    min-height: 42px;
    display: flex;
    align-items: center;
    margin: 6px;
}

.chat-room-container {
    display: flex;
    flex-direction: column;

    height: calc(100% - 49px);
}

.chat-input {
    background-color: rgb(79, 89, 200);
    border: 1px solid rgba(200, 200, 200, 0.2);
}

.chat-input-teacher {
    background-color: rgb(55, 55, 55);
    color: rgb(150, 150, 150);

    &:focus {
        color: rgb(50, 50, 50);
        background-color: rgb(220, 220, 220);
    }

    &::placeholder {
        color: rgb(150, 150, 150);
    }
}
